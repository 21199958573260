<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33.92"
    height="36.96"
    viewBox="0 0 33.92 36.96"
  >
    <g transform="translate(-170.485 -20)">
      <path
        d="M126.935,88.595a101.8,101.8,0,0,0,11.224-7.977c6.078-5.064,6.354-7.119,4.982-8.7-1.1-1.263-2.476-2.658-7.629-5.915A37.21,37.21,0,0,0,128.153,62c-1.808-.589-3.518-.091-3.74.808-.117.478.2,1.01.8,1.472a.006.006,0,0,1,.006.006s.009,0,.014.009c6.057,5.438,6.17,5.565,8.707,8.393.584.65.184,1.406-.561,2.522l-.006.006c-2.526,3.784-8.743,8.664-10.833,10.363a2.7,2.7,0,0,0-1.145,2.744c.254.855,1.623,1.2,3.237,1.03a6.262,6.262,0,0,0,2.305-.755"
        transform="translate(60.586 -32.431)"
        fill="#fa4616"
      />
      <path
        id="colorChange"
        d="M87.224,23.542a81.6,81.6,0,0,0-9,6.395c-4.872,4.058-5.093,5.705-3.993,6.97.882,1.013,1.984,2.132,6.116,4.742a29.78,29.78,0,0,0,5.9,3.214c1.448.474,2.82.074,3-.646.093-.384-.159-.811-.639-1.182,0,0,0,0,0,0s-.007,0-.012-.009c-4.854-4.357-4.945-4.46-6.979-6.727-.468-.522-.147-1.127.449-2.022l0,0c2.026-3.032,7.009-6.944,8.684-8.307a2.166,2.166,0,0,0,.918-2.2c-.2-.686-1.3-.961-2.6-.825a5.034,5.034,0,0,0-1.847.6"
        transform="translate(96.795 -2.904)"
      />
    </g>
  </svg>
</template>

<style>
.light #colorChange {
  fill: #25282a;
}
.dark #colorChange {
  fill: #ffffff;
}
</style>
